import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Row from "../container/row";
import Heading from "../heading";
import Col from "../container/col";

export const InfoPanelWrapper = styled.div`
  // width:100%;
  // height:100%;
  text-align: center;
`;

const InfoPanel = ({ children, title, icon }) => {
  return (
    <InfoPanelWrapper>
      <div>
        {icon && (
          <object width={100} height={100} type="image/svg+xml" data={icon} />
        )}
      </div>

      <Heading elem="h2" size={Heading.sizes.large}>{title}</Heading>
      <div>{children}</div>
    </InfoPanelWrapper>
  );
};

InfoPanel.propTypes = {
  children: PropTypes.any.isRequired,
  icon: PropTypes.object,
  title: PropTypes.string.isRequired
};

InfoPanel.defaultProps = {};

export default InfoPanel;
