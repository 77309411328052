import React from "react";
import BeeIcon from "../images/bee.svg";
import ShareIcon from "../images/share.svg";
import TrackIcon from "../images/track.svg";
import Layout from "../components/layout";
import Container from "../components/container/container";
import InfoPanel from "../components/infopanel/infopanel";
import InfoSlider from "../components/infopanel/infoslider";
import styled from "styled-components";
import Button from "../components/button/button";

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;

  padding-top: ${props => props.theme.spacing.regular};
  padding-left: ${props => props.theme.spacing.large};
  padding-right: ${props => props.theme.spacing.large};
  padding-bottom: ${props => props.theme.spacing.regular};
`;

class WhatPage extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <div>
            <Container>
              <InfoSlider>
                <InfoPanel icon={BeeIcon} title="Learn">
                  <p>
                    We've got loads of articles, videos and courses to help you
                    understand every aspect of your condition – and how to live
                    with it.
                  </p>

                  <p>
                    We’ll create a tailored reading list for you to look at
                    whenever and wherever you want. If you'd prefer something a
                    bit more structured, we have range of NHS-accredited
                    diabetes courses to support you to get to grips with your
                    condition.
                  </p>
                </InfoPanel>

                <InfoPanel icon={TrackIcon} title="Track">
                  <p>
                    You'll need to monitor your blood glucose levels regularly
                    to help you understand how they fluctuate throughout the
                    day.
                  </p>
                  <p>
                    Manually enter information, or share data from other apps to
                    see how effectively you're monitoring your condition.
                  </p>
                  <p>
                    Our digital assistant will help you spot problems before
                    they happen and give you to advice on what to do next.
                  </p>
                </InfoPanel>

                <InfoPanel icon={ShareIcon} title="Share">
                  <p>
                    It's sometimes difficult to remember what has happened
                    between health checks. But it's really important for you and
                    your GP to have a clear picture of any changes in your
                    readings, or patterns of symptoms you might be experiencing.
                  </p>
                  <p>
                    Use the app to track your daily habits, share them with your
                    GP, and keep a record of changes you agree during your
                    appointments.
                  </p>
                </InfoPanel>
              </InfoSlider>
            </Container>
          </div>
        </div>

        <Footer>
          <Button to="/about-you">Let&apos;s Go</Button>
        </Footer>
      </Layout>
    );
  }
}

export default WhatPage;
