import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import SwipeableViews from "react-swipeable-views";

const SingleDot = styled.span`
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-left: ${props => props.theme.spacing.small};
  margin-right: ${props => props.theme.spacing.small};

  ${props =>
    props.active &&
    css`
      background-color: #000;
    `};
`;

const Dots = ({ count, active }) => {
  const dots = [];
  for (let i = 0; i < count; i++) {
    dots.push(<SingleDot key={i} active={active === i} />);
  }

  return <React.Fragment>{dots}</React.Fragment>;
};

export const InfoSliderWrapper = styled.div``;

class InfoSlider extends React.Component {
  state = { activeIndex: 0 };

  onSwitch = (index, event) => {
    if (event === "end") {
      this.setState({ activeIndex: index });
    }
  };

  render() {
    const { children } = this.props;
    const { activeIndex } = this.state;
    return (
      <InfoSliderWrapper>
        <div>
          <SwipeableViews enableMouseEvents onSwitching={this.onSwitch}>
            {children}
          </SwipeableViews>
        </div>

        <div style={{ textAlign: "center" }}>
          <Dots count={children.length} active={activeIndex} />
        </div>
      </InfoSliderWrapper>
    );
  }

  static propTypes = {
    children: PropTypes.any.isRequired
  };

  static defaultProps = {};
}

export default InfoSlider;
